import { version } from './version';
export function getRecommendedForYou({
  indexName,
  threshold,
  queryParameters,
  recommendClient,
  maxRecommendations,
  transformItems = x => x
}) {
  recommendClient.addAlgoliaAgent('recommend-core', version);
  const queries = [{
    indexName,
    threshold,
    queryParameters,
    maxRecommendations
  }];
  return recommendClient.getRecommendedForYou(queries).then(hits => ({
    recommendations: transformItems(hits.results.map(result => result.hits).flat())
  }));
}