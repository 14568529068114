import _extends from "@babel/runtime/helpers/extends";
/** @jsxRuntime classic */
/** @jsx createElement */
import { createDefaultChildrenComponent } from './DefaultChildren';
import { createDefaultFallbackComponent } from './DefaultFallback';
import { createDefaultHeaderComponent } from './DefaultHeader';
import { createListViewComponent } from './ListView';
export function createFrequentlyBoughtTogetherComponent({
  createElement,
  Fragment
}) {
  return function FrequentlyBoughtTogether(props) {
    const translations = {
      title: 'Frequently bought together',
      sliderLabel: 'Frequently bought together products',
      ...props.translations
    };
    const classNames = props.classNames ?? {};
    const children = props.children ?? createDefaultChildrenComponent({
      createElement,
      Fragment
    });
    const FallbackComponent = props.fallbackComponent ?? createDefaultFallbackComponent();
    const Fallback = () => createElement(FallbackComponent, {
      Fragment: Fragment,
      createElement: createElement
    });
    const Header = props.headerComponent ?? createDefaultHeaderComponent({
      createElement,
      Fragment
    });
    const ViewComponent = props.view ?? createListViewComponent({
      createElement,
      Fragment
    });
    const View = viewProps => createElement(ViewComponent, _extends({
      classNames: classNames,
      itemComponent: props.itemComponent,
      items: props.items,
      translations: translations,
      Fragment: Fragment,
      createElement: createElement
    }, viewProps));
    return children({
      classNames,
      Fallback,
      Header,
      recommendations: props.items,
      status: props.status,
      translations,
      View
    });
  };
}