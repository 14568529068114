import { mapByScoreToRecommendations } from './utils';
import { version } from './version';
export function getTrendingFacets({
  recommendClient,
  transformItems = x => x,
  indexName,
  maxRecommendations,
  threshold,
  facetName
}) {
  const query = {
    indexName,
    maxRecommendations,
    threshold,
    facetName
  };
  recommendClient.addAlgoliaAgent('recommend-core', version);
  return recommendClient.getTrendingFacets([query]).then(response => mapByScoreToRecommendations({
    maxRecommendations,
    hits: response.results.map(result => result.hits).flat()
  })).then(hits => ({
    recommendations: transformItems(hits)
  }));
}