/** @jsxRuntime classic */
/** @jsx createElement */

import { cx } from './utils';
export function createFacetsView({
  createElement,
  Fragment
}) {
  return function FacetsView(props) {
    return createElement("div", {
      className: cx('auc-Recommend-container', props.classNames.container)
    }, createElement("ol", {
      className: cx('auc-Recommend-list', props.classNames.list)
    }, props.items.map(item => createElement("li", {
      key: item.facetValue,
      className: cx('auc-Recommend-item', props.classNames.item)
    }, createElement(props.itemComponent, {
      createElement: createElement,
      Fragment: Fragment,
      item: item
    })))));
  };
}